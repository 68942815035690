import { useCallback, useEffect, useState } from 'react';
import { useMapglContext } from './MapglContext';
import type { Task } from './TaskStore';
import { observer } from 'mobx-react-lite';
import { useAppStore } from './AppStoreProvider';

type Lbl = typeof mapgl.Label;

function TaskPin({task}: {task: Task}) {
    const { rulerControl, mapglInstance, mapgl } = useMapglContext();
    const {taskStore} = useAppStore()
    const idx = taskStore.tasksQueue.indexOf(task);

    const priorityToColor = (p: number) => {
        switch (p) {
            case 0:
                return '#E0E0E0';
            case 1:
                return '#FF6666';
            case 2:
                return '#CCCC00';
            case 3:
                return '#a3a2a2';
        }
    }

    useEffect(() => {
        if (!mapgl || !mapglInstance || !rulerControl) {
            return;
        }
        if(task.state == "done") {
            return;
        }
        const inst = new mapgl.CircleMarker(mapglInstance, {
            coordinates: [task.long, task.lat],
            color: task.longest ? "#ffee8c" : priorityToColor(task.priority),
            zIndex: -1
        })
        inst.on('click', () => {
            taskStore.planTask(task)
        })

        
        let firstLabel : any| undefined;
        if(idx != -1) {
            firstLabel = new mapgl.Label(mapglInstance, {
                coordinates: [task.long, task.lat],
                text: (idx + 1).toString(),
                color: '#000000',
                zIndex: 9,
                fontSize: 11
            });
        }
        

        return () => {
            inst.destroy();
            firstLabel?.destroy()
        };
    }, [mapgl, task.lat, task.long, task.state, task.longest, idx])



    return (
        <div key = {task.id}>
        </div>
    );
}

export default observer(TaskPin);
