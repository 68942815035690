import { useCallback, useEffect, useState } from 'react';
import { useMapglContext } from './MapglContext';
import pins from './pins.json';
import { useAppStore } from './AppStoreProvider';
import { observer } from 'mobx-react-lite';

type Coordinate = {lat: number, long: number}
type Pin = typeof pins.clusters[0]

function WorkerPin() {
    const { rulerControl, mapglInstance, mapgl } = useMapglContext();

    const {worker} = useAppStore()

    useEffect(() => {
        if (!mapgl || !mapglInstance || !rulerControl) {
            return;
        }
        const inst = new mapgl.Marker(mapglInstance, {
            coordinates: [worker.long, worker.lat],
            icon: "/man-standing.svg",
            size: [40, 40],
            zIndex: 10
        })

        const ruler = rulerControl.getRuler();
        ruler.enable();
        if(worker.steps && worker.steps.length > 0) {
            const points = worker.steps.map(s => [s.longitude, s.latitude])   
            ruler.setPoints([[worker.long, worker.lat], ...points]);
        }
        else {
            ruler.destroy()
            if (window.outerWidth < 1000) {
                mapglInstance.setCenter([worker.long, worker.lat]);
            }
        }

        let markerDragable = false;
        let dragable = false;
        const mapContainer = mapglInstance.getContainer();
        inst.on('mouseover',()=>{markerDragable=true});
        inst.on('mouseout',()=>{markerDragable=false});

        inst.on('touchstart',()=>{markerDragable=true; dragable=true});
        inst.on('touchend',()=>{markerDragable=false});

        let previousTouch : Touch | undefined;

        const mousedownHanlder = (ev: MouseEvent | TouchEvent)=> {
            if (markerDragable) {
                dragable=true;
            }
        };
        const mouseupHanlder = (ev: MouseEvent | TouchEvent)=> {
            if (dragable){
                worker.move(inst.getCoordinates()[0], inst.getCoordinates()[1])
            }
            dragable=false;
            previousTouch = undefined;
        };
        const mousemoveHanlder = (ev: MouseEvent)=> {
            if (dragable){
                const [x,y]= mapglInstance.project(inst.getCoordinates());
                const position = mapglInstance.unproject([x + ev.movementX, y + ev.movementY]);
                inst.setCoordinates(position)
                ev.stopPropagation();
            }        
        };

        const touchmoveHanlder = (ev: TouchEvent)=> {
            if (dragable){
                const touch = ev.touches[0];
                if (previousTouch) {
                    // be aware that these only store the movement of the first touch in the touches array
                    const movementX = touch.pageX - previousTouch.pageX;
                    const movementY = touch.pageY - previousTouch.pageY;
                    
                    const [x,y]= mapglInstance.project(inst.getCoordinates());
                    const position = mapglInstance.unproject([x + movementX, y + movementY]);
                    inst.setCoordinates(position)
                    ev.stopPropagation();
                }

                previousTouch = touch;
            }        
        };


        mapContainer.addEventListener('mousedown', mousedownHanlder);
        mapContainer.addEventListener('mouseup', mouseupHanlder);
        mapContainer.addEventListener('mousemove', mousemoveHanlder);
        mapContainer.addEventListener("touchstart", mousedownHanlder);
        mapContainer.addEventListener("touchend", mouseupHanlder);
        mapContainer.addEventListener("touchmove", touchmoveHanlder);


        return () => {
            inst.destroy();
            mapContainer.removeEventListener('mousedown', mousedownHanlder);
            mapContainer.removeEventListener('mouseup', mouseupHanlder);
            mapContainer.removeEventListener('mousemove', mousemoveHanlder);
            
            mapContainer.removeEventListener("touchstart", mousedownHanlder);
            mapContainer.removeEventListener("touchend", mouseupHanlder);
            mapContainer.removeEventListener("touchmove", touchmoveHanlder);
        };
    }, [mapgl, worker.lat, worker.long, worker.steps])



    return (
        <div>
        </div>
    );
}

export default observer(WorkerPin);
