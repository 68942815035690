import { useCallback, useEffect, useState } from 'react';
import { useMapglContext } from './MapglContext';
import type { Task } from './TaskStore';
import { observer } from 'mobx-react-lite';
import { useAppStore } from './AppStoreProvider';
import { TechStation } from './TechStation';

type Lbl = typeof mapgl.Label;

function TechStationPin({station}: {station: TechStation}) {
    const { rulerControl, mapglInstance, mapgl } = useMapglContext();

    useEffect(() => {
        if (!mapgl || !mapglInstance || !rulerControl) {
            return;
        }

        const typeToIcon = (p: 0|1|3) => {
            switch (p) {
                case 0:
                    return '/tech.svg';
                case 1:
                    return '/charging.svg';
                case 3:
                    return '/locker.svg';
            }
        }
        
        const inst = new mapgl.Marker(mapglInstance, {
            coordinates: [station.long, station.lat],
            icon: typeToIcon(station.type),
            size: [50, 50],
            zIndex: 1,
        })
        

        return () => {
            inst.destroy();
        };
    }, [mapgl])



    return (
        <div key = {station.id}>
        </div>
    );
}

export default observer(TechStationPin);
