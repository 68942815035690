import { useCallback, useState } from 'react';
import { useMapglContext } from './MapglContext';
import pins from './pins.json';
import {RouteResults, TableResults, TripResults} from 'osrm';
import { Marker } from '@2gis/mapgl/types';
import { useAppStore } from './AppStoreProvider';
import TaskPin from './TaskPin';
import { observer } from 'mobx-react-lite';
import TechStationPin from './TechStationPin';



function TasksMap() {
    const { rulerControl, mapglInstance, mapgl } = useMapglContext();

    const {taskStore, techStations} = useAppStore()

    return (
        <div>
            {taskStore.tasks.map((task) => {
                return (
                <div key={task.id}>
                    <TaskPin task={task}/>
                    </div> )
                })}
            {techStations.map((station) => {
                return (
                <div key={station.id}>
                    <TechStationPin station={station}/>
                    </div> )
                })}
        </div>
    );
}

export default observer(TasksMap);
