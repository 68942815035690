import { createContext, useContext } from 'react';
import type {PropsWithChildren} from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Task, TaskStore } from './TaskStore';
import { Worker } from './Worker';
import pins from './pins.json';
import tasks_arch from './ops_tasks_archive.json';
import { MAP_CENTER } from './Mapgl';
import tech_stations from './TechStations.json';
import { TechStation } from './TechStation';

const priorityToNumber = (p: string) => {
    switch (p) {
        case "Low":
            return 3;
        case "Medium":
            return 2;
        case "High":
            return 1;
        case "Critical":
            return 1;
    }
    return 3;
}

const priorityNumberToNumber = (p: number) => {
    switch (p) {
        case 0:
            return 3;
        case 10:
            return 2;
        case 20:
            return 1;
        case 30:
            return 1;
    }
    return 3;
}

const priorityToPrice = (p: number) => {
    switch (p) {
        case 3:
            return 20;
        case 2:
            return 30;
        case 1:
            return 40;
    }
    return 20;
}

const taskStore = new TaskStore();
 taskStore.tasks = pins.clusters.map((cluster) => {
    return new Task(
        cluster.opsTaskIds[0],
        cluster.centerCoordinate.lat,
        cluster.centerCoordinate.long,
        priorityToNumber(cluster.priority),
        priorityToPrice(priorityToNumber(cluster.priority)),
    )
})

// taskStore.tasks = tasks_arch.map((t) => {
//     return new Task(
//         t._id.$oid,
//         t.Issues[0].Location[0],
//         t.Issues[0].Location[1],
//         priorityNumberToNumber(t.Priority),
//         priorityToPrice(priorityNumberToNumber(t.Priority)),
//     )
// })

const techStations = tech_stations.map((t) => {return new TechStation(
    t._id.$oid,
    t.Location.Coordinates[0],
    t.Location.Coordinates[1],
    t.Type as 0 | 1 | 3
)})

const  worker = new Worker(
    MAP_CENTER[1],
    MAP_CENTER[0],
    35,
    12,
    12,
    taskStore,
    techStations
);

const store = {
    worker,
    taskStore,
    techStations
};

const Context = createContext(store);

export const AppStoreProvider = observer(({ children, ...props } : PropsWithChildren) => {
  const localStore = useLocalObservable(() => store);
  return <Context.Provider value={localStore}>{children}</Context.Provider>;
});

export const useAppStore = () => {
  const store = useContext(Context);
  if (!store) throw new Error('Use App store within provider!');
  return store;
};