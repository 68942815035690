import { useCallback, useEffect, useState } from 'react';
import { useMapglContext } from './MapglContext';
import pins from './pins.json';
import {RouteResults, TableResults, TripResults} from 'osrm';
import { Marker } from '@2gis/mapgl/types';
import { useAppStore } from './AppStoreProvider';
import { observer } from 'mobx-react-lite';

type Coordinate = {lat: number, long: number}
type Pin = typeof pins.clusters[0]

function WorkerResult() {
    const { rulerControl, mapglInstance, mapgl } = useMapglContext();

    const {worker} = useAppStore()

    return (
        <div>
            <span>Расстояние: {Math.round(worker.distance / 1000)} км    </span>
            <span>Время: {new Date(worker.time * 1000).toISOString().substring(11, 16)}   </span>
            <span>К-во замен: {worker.income} </span>
            <span>Запас батарей: {worker.batteriesCount}</span>
        </div>
    );
}

export default observer(WorkerResult);
