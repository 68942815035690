import {  makeObservable, observable } from 'mobx';
import { TaskStore, Task } from './TaskStore';
import { RouteBuilder, RouteStep } from './RouteBuilder';
import { TechStation } from './TechStation';

type Coordinate = {lat: number, long: number}


export class Worker {
    @observable lat: number;
    @observable long: number;
    @observable speed: number;
    @observable batteriesCount: number;
    @observable batteriesCapacity: number;
    @observable income: number;
    @observable distance: number;
    @observable time: number;
    @observable inActive: boolean = true;
    taskStore: TaskStore;
    stations: TechStation[];
    taskInProgress: Task | TechStation | undefined;
    steps: RouteStep[]| undefined;
    routeBuilder: RouteBuilder;

    constructor(
        lat: number,
        long: number,
        speed: number,
        batteriesCount: number,
        batteriesCapacity: number,
        taskStore: TaskStore,
        stations: TechStation[]
    ) {
        this.lat = lat;
        this.long = long;
        this.speed = speed;
        this.batteriesCount = batteriesCount;
        this.batteriesCapacity = batteriesCapacity;
        this.taskStore = taskStore;
        this.stations = stations;
        this.routeBuilder = new RouteBuilder();
        this.income= 0;
        this.distance = 0;
        this.time = 0;

        makeObservable(this);

    }

    public start() {
        this.inActive = true;
    }

    public stop() {
        this.inActive = false;
    }

    public move( long: number, lat: number) {
        this.lat = lat;
        this.long = long;
        this.taskStore.clearQueue();
        this.taskInProgress = undefined;
        this.doPlan();
    }

    private doWork() {
        if(this.taskInProgress){
            if(this.taskInProgress instanceof Task) {
                this.income += 1;
                this.time += 90;
                this.batteriesCount-= 1;
                this.taskInProgress.state = "done";
            }
            else {
                this.batteriesCount = this.batteriesCapacity;
                this.time += 600;
            }
            this.taskInProgress= undefined;
            this.doPlan();
        }
    }

    private doStep() {
        if(this.steps){
            const step = this.steps.shift();
            if(step){
                this.distance += step.distance;
                this.time += step.duration / 2.5;
                this.long = step.longitude;
                this.lat = step.latitude;
                setTimeout(() => this.doStep(), 200);
            }
            else {
                this.doWork();
            }
        }
    }

    private doPlan() {
        //this.taskInProgress = this.taskStore.tasksQueue.shift();
    
        if(this.taskInProgress){
            if(this.taskInProgress instanceof Task) {
                this.taskInProgress.state = "reserve"
            }

            const requestOptions : RequestInit = {
                method: "GET"
    
                };
    
                this.routeBuilder.route(this, this.taskInProgress)
                .then((response) => {
                    this.steps = response;
                    this.doStep();
                })
                .then((result) => console.log(result))
                .catch((error) => console.error(error));
        }
        else {
            this.startWorking();
        }
    }

    private startWorking() {

        this.routeBuilder.dispatch(this, this.taskStore.tasks.filter(task => task.state != "done"), this.stations, this.batteriesCount, this.batteriesCount < (this.batteriesCapacity / 2))
            .then((location) => {
                if(location && location.length > 0){
                    //this.taskStore.planTask(task);
                    this.taskStore.tasksQueue = location.filter(t => t && t.type == 0).map(l => this.taskStore.tasks.find(t => t.id == l.id)!).filter(t => t);
                    if(location[0].type == 0)
                    {
                        this.taskInProgress = this.taskStore.tasks.find(t => t.id == location[0].id)!;
                    }
                    else 
                    {
                        this.taskInProgress = this.stations.find(t => t.id == location[0].id)!;
                    }
                    this.doPlan();
                }
            });
    }


}
