import './App.css';
import Mapgl from './Mapgl';
import { MapglContextProvider } from './MapglContext';
import { AppStoreProvider } from './AppStoreProvider';
import TasksMap from './TasksMap';
import WorkerPin from './WorkerPin';
import WorkerResult from './WorkerResult';

function App() {
    return (
        <AppStoreProvider>
            <MapglContextProvider>
                <div>
                    <div className='App-buttons'>
                        <div>
                            <WorkerResult/>
                        </div>
                    </div>

                    <div>
                        <TasksMap/>
                        <WorkerPin/>
                    </div>
                    <div className='App-map-container'>
                        <Mapgl />
                    </div>
                </div>
            </MapglContextProvider>
        </AppStoreProvider>
    );
}

export default App;
