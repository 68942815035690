import type { Task } from './TaskStore';
import { TechStation } from './TechStation';
import type { Worker } from './Worker';

type Coordinate = {lat: number, long: number}

type DispatchWorker = {
    "longitude": number,
    "latitude": number,
    "batteriesCount": number
};

type DispatchTask = {
    "id": string,
    "longitude": number,
    "latitude": number,
    "priority": number
};

type Station = {
    "id": string,
    "longitude": number,
    "latitude": number
};

enum TaskType {
    "Task"= 0,
    "Station"= 1,
}

type DispatchRes = {
    "id": string,
    "type": TaskType
};

type RouteCoordinate = {
    "longitude": number,
    "latitude": number
};

export type RouteStep = {
    "longitude": number,
    "latitude": number,
    "distance": number,
    "duration": number
};



export class RouteBuilder {

    constructor(
    ) {
    }

    public async dispatch(worker: Worker, tasks:Task[], techStations: TechStation[], batteriesCount: number, needStation: boolean) {

        const dispatchWorker: DispatchWorker = {
            latitude: worker.lat,
            longitude: worker.long,
            batteriesCount: batteriesCount
        }

        const opsTasks: DispatchTask[] = tasks.map(task => {
            return {
                latitude: task.lat,
                longitude: task.long,
                priority: task.priority,
                id: task.id
            }
        })

        const stations: Station[] = techStations.map(station => {return {id: station.id, latitude: station.lat, longitude: station.long}});
        

        const requestOptions : RequestInit = {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({worker: dispatchWorker, tasks: opsTasks, stations: stations, needStation: needStation}),
            };

            return fetch(`http://130.193.42.47:5275/TaskDispatcher/Dispatch`, requestOptions)
            .then((response) => {
                return response.json().then((res : DispatchRes[]) => {
                    return res;
                })
            });
    }

    public async route(start: Coordinate, end: Coordinate): Promise<RouteStep[]> {

        const routeStart: RouteCoordinate = {
            latitude: start.lat,
            longitude: start.long
        }

        const routeEnd: RouteCoordinate = {
            latitude: end.lat,
            longitude: end.long
        }

        const requestOptions : RequestInit = {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({start: routeStart, end: routeEnd}),
            };

            return fetch(`http://130.193.42.47:5275/TaskDispatcher/Route`, requestOptions)
            .then((response) => {
                return response.json().then((res : RouteStep[]) => {
                    return res;
                })
            });
    }

}
