import { makeObservable, observable } from 'mobx';

type Coordinate = {lat: number, long: number}


export class Task {
    id: string;
    @observable lat: number;
    @observable long: number;
    @observable priority: 1 | 2| 3;
    @observable price: number;
    @observable state: "todo" | "reserve" | "done" = "todo";
    @observable longest: boolean;

    constructor(
        id: string,
        lat: number,
        long: number,
        priority:  1 | 2| 3,
        price: number
    ) {
        this.id = id;
        this.lat = lat;
        this.long = long;
        this.priority = priority;
        this.price = price;
        this.longest= false;
        makeObservable(this);
    }

}

export class TaskStore {
    @observable tasks: Task[];
    @observable tasksQueue: Task[];

    constructor(
    ) {
        this.tasks = observable([])
        this.tasksQueue = observable([])

        makeObservable(this);
    }

    public addTask(task: Task) {
        this.tasks.push(task);
    }

    public removeTask(task: Task) {
        this.tasks.splice(this.tasks.indexOf(task), 1);
    }

    public planTask(task: Task) {
        this.tasksQueue.push(task);
    }

    public clearQueue() {
        this.tasksQueue.splice(0, this.tasksQueue.length);
    }

}
