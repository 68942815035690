import { makeObservable, observable } from 'mobx';

type Coordinate = {lat: number, long: number}


export class TechStation {
    id: string;
    @observable lat: number;
    @observable long: number;
    @observable type: 0 | 1| 3;

    constructor(
        id: string,
        lat: number,
        long: number,
        type:  0 | 1| 3
    ) {
        this.id = id;
        this.lat = lat;
        this.long = long;
        this.type = type;
        makeObservable(this);
    }

}